import { Box, List, ListItem, Typography } from "@mui/material";
import { Container } from "@mui/system";

export function PrivacyPolicy() {
	return (
		<Container component="main" maxWidth="xs" style={{ padding: 0 }}>
			<Box px={3} py={3}>
				<Typography component="h1" variant="h5">
					プライバシーポリシー
				</Typography>

				<Typography variant="body2" mt={2}>
					株式会社ヘルスアンドライツ（以下「当運営者」といいます。）は、当運営者が管理するサービス「ケアミー」及び同サービスに関連するすべてのサービス（以下「本サービス」と総称します。）におけるユーザーについての個人情報を含むユーザーの情報（以下「ユーザー情報」といいます。）の取り扱いについて、以下の通りプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
				</Typography>

				<Typography component="h2" variant="h6" mt={4}>
					1 当運営者が取得するユーザー情報
				</Typography>

				<Typography variant="body2" mt={2}>
					当運営者が、本サービスにおいて取得するユーザー情報は、以下の通りです。
				</Typography>

				<List sx={{ listStyleType: "decimal", pl: 2.5 }}>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						メールアドレスの情報
						<br />
						当運営者は、ユーザーが本サービスのアカウントを作成するにあたり、一定のメールアドレスの情報の提供をお願いしています。
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						アクション情報
						<br />
						当運営者は、本サービスの利用に関するユーザーのアクションに関する情報を、その日時とともに収集いたします。
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						本サービスの利用情報
						<br />① 端末情報
						<br />
						当運営者は、ユーザーが利用する機器情報（OS、端末の個体識別情報、コンピュータ名等）を取得する場合があります。
						<br />② ログ情報
						<br />
						ユーザーが本サービスを利用した際の IP
						アドレス、ブラウザ種類、ブラウザ言語等の情報が自動で生成、保存されます。また、当運営者は、Cookie
						や JavaScript
						などの技術を利用し、ユーザーの行動履歴を取得することがあります。
					</ListItem>
				</List>

				<Typography variant="body2" mt={2}>
					※
					本サービスでは、ユーザーがより便利に本サービスを利用して頂けるよう「Cookie」という技術を使用することがあります。これは、ユーザーのコンピュータが本サービス上のどのページにアクセスしたかを記録しますが、ユーザーが本サービスにおいてご自身の個人情報を入力されない限りユーザーを特定したり、識別することはできません。Cookie
					の使用を希望されない場合は、ご本人のブラウザの設定を変更することにより、Cookie
					の使用を拒否することができますが、その場合には、本サービスの一部又は全部をご利用できなくなる場合がありますので、あらかじめご了承ください。
				</Typography>

				<Typography component="h2" variant="h6" mt={4}>
					2 ユーザー情報の利用目的
				</Typography>
				<Typography variant="body2" mt={2}>
					当運営者は取得したユーザー情報を以下の目的で利用いたします。
				</Typography>
				<List sx={{ listStyleType: "decimal", pl: 2.5 }}>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						ユーザーごとにカスタマイズされたコンテンツの提供、広告
						(電子メールによるメールマガジンなどを含みます。) の配信をするため
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						利用規約違反その他本サービスの不正・不当利用に対する対応のため
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						データ入力補助その他ユーザーが本サービスを円滑に利用できるようにするため
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						本サービスの利用に関する統計データを作成するため
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						ユーザーからのお問い合わせ等（本人確認を行うことも含みます。）に対する対応のため
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						本サービスに関する情報等又は当運営者以外の事業者が広告主となる広告情報等を告知するため
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						今後の本サービスに関する新企画立案を行い提供するため
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						有料サービス利用時等における請求処理のため
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						マーケティング調査及び分析のため
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						その他本サービスに関するお知らせをしたり、必要に応じた連絡を行ったりするため
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						その他、上記利用目的に付随する目的のため
					</ListItem>
				</List>

				<Typography component="h2" variant="h6" mt={4}>
					3 ユーザー情報の第三者への開示・提供
				</Typography>

				<List sx={{ listStyleType: "decimal", pl: 2.5 }}>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						当運営者は、取得したユーザー情報を、本条第２項および以下の場合を除き、第三者に提供することはございません。
						<List sx={{ listStyleType: "disc", pl: 2.5 }}>
							<ListItem
								disablePadding
								sx={{ display: "list-item", typography: "body2" }}
							>
								第三者に提供することについて事前の同意を頂いている場合
							</ListItem>
							<ListItem
								disablePadding
								sx={{ display: "list-item", typography: "body2" }}
							>
								個人情報保護法及びその他法令に基づく場合
							</ListItem>
							<ListItem
								disablePadding
								sx={{ display: "list-item", typography: "body2" }}
							>
								ユーザーが本サービスの利用規約に違反し、当運営者の権利、財産やサービス等を保護するために、ユーザー情報を必要最小限の範囲で開示せざるをえないと判断するに足る十分な根拠がある場合
							</ListItem>
							<ListItem
								disablePadding
								sx={{ display: "list-item", typography: "body2" }}
							>
								人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合
							</ListItem>
							<ListItem
								disablePadding
								sx={{ display: "list-item", typography: "body2" }}
							>
								公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合
							</ListItem>
							<ListItem
								disablePadding
								sx={{ display: "list-item", typography: "body2" }}
							>
								国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
							</ListItem>
							<ListItem
								disablePadding
								sx={{ display: "list-item", typography: "body2" }}
							>
								業務委託契約に基づいて、ユーザー情報の取扱いの一部又は全部を外部の会社に委託する場合（サーバーへの蔵置及び本サービスの品質を向上する目的で、業務委託先に情報の管理及び分析を委託する場合も含みます）。この場合、当運営者は委託先との間でユーザー情報の取り扱いに関する契約を締結するとともに委託先においてユーザー情報の安全管理が図られるよう適切に監督いたします。
							</ListItem>
							<ListItem
								disablePadding
								sx={{ display: "list-item", typography: "body2" }}
							>
								合併、会社分割、事業譲渡その他の事由によって事業の継承が行われる場合
							</ListItem>
						</List>
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						当運営者は、(ⅰ)特定の技術情報（IPアドレス、携帯端末識別子を含む）、(ⅱ)個人を特定できない統計情報、(ⅲ)個人を特定できないサービスの利用に関する情報を第三者に提供することがあります。
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						ユーザーは、このような情報を当運営者または第三者がユーザー傾向分析、人口統計分析、ウェブ分析のため、また、ユーザーに行動ターゲティング広告と呼ばれる広告のために使用することに同意するものとします。
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						前二項に基づいて第三者に提供する場合、当運営者は、当該第三者への提供の必要性や当該第三者への提供方法、当該第三者による安全管理措置の実施状況、当運営者と当該第三者の関係（当運営者の提供するサービスと第三者の提供するサービスの関係、資本関係の有無など）の事情を総合的に考慮したうえで、お客様のプライバシーへの影響を慎重に判断し、必要な範囲に限って第三者に提供します。
					</ListItem>
				</List>

				<Typography component="h2" variant="h6" mt={4}>
					4 個人情報の開示
				</Typography>

				<Typography variant="body2" mt={2}>
					当運営者は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）但し、個人情報保護法その他の法令により、当運営者が開示の義務を負わない場合は、この限りではありません。
				</Typography>

				<Typography component="h2" variant="h6" mt={4}>
					5 個人情報の訂正及び利用停止等
				</Typography>

				<Typography variant="body2" mt={2}>
					当運営者は、ユーザーから、（1）個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加または削除（以下「訂正等」といいます。）を求められた場合、及び（2）あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止または消去（以下「利用停止等」といいます。）を求められた場合には、ユーザーご本人からのご請求であることを確認の上で、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等または利用停止等を行い、その旨をユーザーに通知します（訂正等または利用停止等を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当運営者が訂正等及び利用停止等の義務を負わない場合は、この限りではありません。
				</Typography>

				<Typography component="h2" variant="h6" mt={4}>
					6 個人情報の削除
				</Typography>

				<List sx={{ listStyleType: "decimal", pl: 2.5 }}>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						ユーザーは、当運営者が法令上義務を負わない場合を除き、当運営者所定の手続にしたがって、個人情報の削除を請求することができます。
					</ListItem>
					<ListItem
						disablePadding
						sx={{ display: "list-item", typography: "body2" }}
					>
						削除の請求にあたっては、その旨を記載した上で以下のメールアドレス宛にご連絡ください。メールを受信後に、別途ご案内を差し上げます。
					</ListItem>
				</List>
				<Typography variant="body2" mt={2}>
					info(at-mark)healthandrights.jp
				</Typography>

				<Typography component="h2" variant="h6" mt={4}>
					7 プライバシーポリシーの改定
				</Typography>

				<Typography variant="body2" mt={2}>
					当運営者は、ユーザー情報の取扱いについて継続的に改善するため、本ポリシーを改定することがあります。なお、法令上ユーザーの同意が必要となる内容の改定の場合は、当運営者所定の方法でユーザーの同意を得るものとします。
				</Typography>
			</Box>
		</Container>
	);
}
