import { Box, Typography } from "@mui/material";
import Image from "next/image";

export function ReviewComment({
	title,
	description,
}: { title: string; description: string }) {
	return (
		<>
			<Box mt={8} textAlign="center">
				<Image src={"./star-five.png"} width={115} height={25} alt="" />
			</Box>

			<Typography mt={1} fontWeight="700" textAlign="center">
				{title}
			</Typography>

			<Typography mt={1}>{description}</Typography>
		</>
	);
}
