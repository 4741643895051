import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";

export function SpecifiedCommercialTransactionLaw() {
	return (
		<Container component="main" maxWidth="xs" style={{ padding: 0 }}>
			<Box p={3}>
				<Typography component="h1" variant="h5">
					特定商取引法に基づく表記
				</Typography>

				<Typography variant="subtitle2" component="h6" mt={3}>
					事業者名
				</Typography>

				<Typography variant="body2" mt={1}>
					株式会社ヘルスアンドライツ
				</Typography>

				<Typography variant="subtitle2" component="h6" mt={3}>
					所在地
				</Typography>

				<Typography variant="body2" mt={1}>
					東京都渋谷区渋谷3-6-2 エクラート渋谷
				</Typography>

				<Typography variant="subtitle2" component="h6" mt={3}>
					代表者
				</Typography>

				<Typography variant="body2" mt={1}>
					代表取締役 吉川雄司
				</Typography>

				<Typography variant="subtitle2" component="h6" mt={3}>
					お問い合わせ先
				</Typography>

				<Typography variant="body2" mt={1}>
					info@healthandrights.jp
				</Typography>

				<Typography variant="subtitle2" component="h6" mt={3}>
					利用料
				</Typography>

				<Typography variant="body2" mt={1}>
					購入手続きの際に画面に表示されます。消費税は内税として表示しております。
				</Typography>

				<Typography variant="subtitle2" component="h6" mt={3}>
					利用料以外に発生する料金等
				</Typography>

				<Typography variant="body2" mt={1}>
					サイトの閲覧、コンテンツのダウンロード、お問い合わせ等の際の電子メールの送受信時などに、所定の通信料が発生します。
				</Typography>

				<Typography variant="subtitle2" component="h6" mt={3}>
					決済方法
				</Typography>

				<Typography variant="body2" mt={1}>
					iOSアプリまたはAndroidアプリ：クレジットカード決済の他、携帯電話会社などによる収納をご利用いただけます。
					<br />
					Webサイト：クレジットカード決済をご利用いただけます。
				</Typography>

				<Typography variant="subtitle2" component="h6" mt={3}>
					サービス開始時期と終了時期
				</Typography>

				<Typography variant="body2" mt={1}>
					当社所定の手続き終了後、直ちにご利用頂けます。
					契約期間途中の解約となった場合であっても契約満了日までの料金が発生し、日割精算等による返金を含めた一切の返金は行われません。その場合、サービスも契約満了日まで提供されます。
				</Typography>

				<Typography variant="subtitle2" component="h6" mt={3}>
					返品についての特約事項
				</Typography>

				<Typography variant="body2" mt={1}>
					電子商品としての性質上、返品には応じられません。
				</Typography>

				<Typography variant="subtitle2" component="h6" mt={3}>
					推奨環境
				</Typography>

				<Typography variant="body2" mt={1}>
					OS：最新のiOSまたはAndroid
					<br />
					アプリバージョン：App StoreまたはPlay
					Storeで提供している最新バージョン
					<br />
					ブラウザ：最新のSafariまたはGoogle Chrome
				</Typography>

				<Typography variant="subtitle2" component="h6" mt={3}>
					注意事項
				</Typography>

				<Typography variant="body2" mt={1}>
					本サービスは、特定商取引法に規定されるクーリング・オフが適用されるサービスではありません。
				</Typography>
			</Box>
		</Container>
	);
}
