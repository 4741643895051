import { APP_DOWNLOAD_LINK } from "@/config/constants";
import { Box, Typography } from "@mui/material";
import Image from "next/image";
import Link from "next/link";

export function DownloadCareme() {
	return (
		<Box
			px={3}
			py={5.5}
			sx={{
				backgroundImage: "url(./top/rounded-background.png)",
				backgroundSize: "cover",
			}}
		>
			<Box display={"flex"} flexDirection="column" alignItems="center">
				<Box mb={3}>
					<Image
						src={"/app-icon.png"}
						width={76}
						height={76}
						alt="caremeのロゴ"
					/>
				</Box>
				<Typography variant="h6" mb={3} textAlign="center" fontWeight={700}>
					App Store・Play Storeから
					<br />
					ケアミーをダウンロード
				</Typography>
				<Box
					display={"flex"}
					flexDirection="row"
					alignItems="center"
					gap="0px 8px"
				>
					<Link
						href={APP_DOWNLOAD_LINK.appStore}
						target="_blank"
						rel="noopener noreferrer"
					>
						<Image
							src={"/app-store-banner.png"}
							height={40}
							width={109}
							alt="App Store へのリンク"
						/>
					</Link>
					<Link
						href={APP_DOWNLOAD_LINK.googlePlayStore}
						target="_blank"
						rel="noopener noreferrer"
					>
						<Image
							src={"/google-play-store-banner.png"}
							height={38}
							width={128}
							alt="Google Play Store へのリンク"
						/>
					</Link>
				</Box>
			</Box>
		</Box>
	);
}
