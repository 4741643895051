import { ThemeColors } from "@/config/colors";
import { APP_DOWNLOAD_LINK } from "@/config/constants";
import { DownloadCareme } from "@/features/misc/components/DownloadCareme";
import { ReviewComment } from "@/features/misc/components/ReviewComment";
import { useMobileDetect } from "@/hooks/useMobileDetect";
import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

export const Landing = () => {
	const mobileDetect = useMobileDetect();
	const [downloadLink, setDownloadLink] = useState("");

	useEffect(() => {
		setDownloadLink(
			mobileDetect.isAndroid()
				? APP_DOWNLOAD_LINK.googlePlayStore
				: APP_DOWNLOAD_LINK.appStore,
		);
	}, [mobileDetect]);

	return (
		<>
			<Container
				component="header"
				maxWidth="xs"
				style={{
					padding: 0,
					position: "sticky",
					top: 0,
					backgroundColor: "white",
				}}
			>
				<Box
					px={2}
					height={64}
					display="flex"
					alignItems="center"
					justifyContent="space-between"
				>
					<Image
						src="/logo-green.png"
						width={130}
						height={27}
						alt="ケアミーのロゴ"
					/>
					<Link href={downloadLink} style={{ textDecoration: "none" }}>
						<Box
							bgcolor={ThemeColors[0].colors.primary[500]}
							borderRadius={50}
							px={2}
							py={1.5}
						>
							<Typography variant="subtitle2" color={"white"}>
								アプリをダウンロード
							</Typography>
						</Box>
					</Link>
				</Box>
			</Container>

			<Container component="main" maxWidth="xs" style={{ padding: 0 }}>
				<Box px={3}>
					<Typography variant="h4" component="h1" textAlign="center" mt={6}>
						PMSの
						<br />
						予測・共有アプリ
					</Typography>
				</Box>

				<Box mt={5.5} display={"flex"} justifyContent="center">
					<Box
						height={400}
						width={228}
						sx={{
							backgroundImage: "url(/top/careme-device.png)",
							backgroundSize: "228px",
							backgroundRepeat: "no-repeat",
							backgroundPositionX: "center",
							backgroundPositionY: "top",
							position: "relative",
							zIndex: -1,
						}}
					>
						<Box
							position={"absolute"}
							width={108}
							height={108}
							bgcolor={ThemeColors[0].colors.primary[400]}
							borderRadius="50%"
							display={"flex"}
							justifyContent="center"
							alignItems={"center"}
							left={-48}
							top={104}
						>
							<Typography
								variant="body2"
								fontWeight={700}
								textAlign="center"
								lineHeight={1.25}
							>
								カラダや
								<br />
								ココロの
								<br />
								変化を予測
							</Typography>
						</Box>

						<Box
							position={"absolute"}
							width={108}
							height={108}
							bgcolor={ThemeColors[0].colors.primary[400]}
							borderRadius="50%"
							display={"flex"}
							justifyContent="center"
							alignItems={"center"}
							right={-48}
							top={144}
						>
							<Typography
								variant="body2"
								fontWeight={700}
								textAlign="center"
								lineHeight={1.25}
							>
								LINEで
								<br />
								パートナーに
								<br />
								簡単共有
							</Typography>
						</Box>
					</Box>
				</Box>

				<Box mt={-17}>
					<Box
						px={3}
						pt={8}
						pb={5.5}
						sx={{
							backgroundImage: "url(/top/rounded-background.png)",
							backgroundSize: "cover",
						}}
					>
						<Box display={"flex"} flexDirection="column" alignItems="center">
							<Typography mb={3} lineHeight={1.65}>
								ケアミーはPMSの予測・共有アプリです。カラダやココロの変化を予測したり、LINEでパートナーに共有したりできます。あなたの代わりにケアミーがLINEでパートナーに通知するので、PMSのことを自分の言葉でうまく説明できない、心理的にうまく伝えられないという方におすすめです。通知には産婦人科医監修の基礎知識も添えられているので、パートナーにPMSのことを理解してもらえます。
							</Typography>
							<Box
								display={"flex"}
								flexDirection="row"
								alignItems="center"
								width="100%"
								gap="0px 8px"
							>
								<Link
									href={APP_DOWNLOAD_LINK.appStore}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Image
										src={"/app-store-banner.png"}
										height={40}
										width={109}
										alt="App Store へのリンク"
									/>
								</Link>
								<Link
									href={APP_DOWNLOAD_LINK.googlePlayStore}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Image
										src={"/google-play-store-banner.png"}
										height={38}
										width={128}
										alt="Google Play Store へのリンク"
									/>
								</Link>
							</Box>
						</Box>
					</Box>
				</Box>

				<Box px={3} display="flex" flexDirection={"column"} alignItems="center">
					<Typography
						m={8}
						variant="h6"
						component="h2"
						textAlign="center"
						color={ThemeColors[0].colors.primary[600]}
					>
						ケアミーの特徴
					</Typography>

					<Image
						width={80}
						height={80}
						src={"/top/icon-talk-notification.png"}
						alt="カラダやココロの変化をLINEでパートナーに共有"
					/>

					<Typography mt={3} variant="h5" component="h2" textAlign="center">
						カラダやココロの変化を
						<br />
						LINEでパートナーに共有
					</Typography>

					<Typography mt={3}>
						ペアリング機能を使えば、カラダやココロの変化をパートナーに知ってもらうことができます。あなたの代わりにケアミーがパートナーのLINEに通知するので、PMSのことを自分の言葉でうまく説明できない、心理的にうまく伝えられないという方におすすめです。
					</Typography>

					<Box mt={11}>
						<Image
							width={80}
							height={80}
							src={"/top/icon-pairing-message.png"}
							alt="PMSの基礎知識を自動で通知"
						/>
					</Box>

					<Typography
						mt={3}
						variant="h5"
						component="h2"
						fontWeight="700"
						textAlign="center"
					>
						PMSの基礎知識を自動で通知
					</Typography>

					<Typography mt={3}>
						ペアリング機能の通知には産婦人科医監修のPMSの基礎知識が添えられているので、パートナーにPMSのことを理解してもらえます。
					</Typography>

					<Box mt={11}>
						<Image
							width={80}
							height={80}
							src={"/top/icon-calendar.png"}
							alt="PMSを予測してカレンダーに表示"
						/>
					</Box>

					<Typography
						mt={3}
						variant="h5"
						component="h2"
						fontWeight="700"
						textAlign="center"
					>
						PMSを予測して
						<br />
						カレンダーに表示
					</Typography>

					<Typography mt={3}>
						不調になりやすい期間を予測してカレンダーに表示します。そろそろ生理前かな？と思ったらアプリを開くだけでパッとチェックできます。不調になりやすい期間をあらかじめ知ることができるので、スケジュール調整にも役立ちます。
					</Typography>

					<Box mt={11}>
						<Image
							width={80}
							height={80}
							src={"/top/icon-pairing.png"}
							alt="カレンダーをパートナーに共有"
						/>
					</Box>

					<Typography mt={3} variant="h5" component="h2" textAlign="center">
						カレンダーを
						<br />
						パートナーに共有
					</Typography>

					<Typography mt={3}>
						パートナーもPMSの期間や症状をカレンダーで確認できます。パートナーにもケアミーをインストールしてもらうことで、自分のケアミーに表示されている不調になりやすい期間の予測や症状を共有できます。PMSの期間や症状をより詳しく共有したい人におすすめです。
					</Typography>

					<Box mt={11}>
						<Image
							width={80}
							height={80}
							src={"/top/icon-period-log.png"}
							alt="PMSの症状をセルフチェック"
						/>
					</Box>

					<Typography mt={3} variant="h5" component="h2" textAlign="center">
						PMSの症状をセルフチェック
					</Typography>

					<Typography mt={3}>
						カラダの症状やココロの状態など、PMSの症状をワンタップで記録することができます。いつ、どんな症状が出やすいのか振り返ることができます。
					</Typography>

					<Box mt={11}>
						<Image
							width={80}
							height={80}
							src={"/top/icon-chat.png"}
							alt="不安や悩みをチャットで相談"
						/>
					</Box>

					<Typography mt={3} variant="h5" component="h2" textAlign="center">
						不安や悩みをチャットで相談
					</Typography>

					<Typography mt={3}>
						PMSに対するセルフケアの方法や病院受診の目安がわかります。チャットで相談すると産婦人科医や専門知識を持ったスタッフが回答します。
					</Typography>

					<Box mt={11}>
						<Image
							width={96}
							height={44}
							src={"/top/icon-theme-color.png"}
							alt="選べるテーマカラー"
						/>
					</Box>

					<Typography mt={3} variant="h5" component="h2" textAlign="center">
						選べるテーマカラー
					</Typography>

					<Typography mt={3}>
						14種類のテーマカラーから、自分の好みや気分に合わせて選ぶことができます。
					</Typography>
				</Box>

				<Box mt={11}>
					<DownloadCareme />
				</Box>

				<Box px={3}>
					<Typography
						mt={8}
						variant="h6"
						component="h2"
						textAlign="center"
						color={ThemeColors[0].colors.primary[600]}
					>
						監修医師
					</Typography>

					<Box mt={8} textAlign="center">
						<Image
							src="/top/dr-yoko-gekka.jpg"
							width={132}
							height={132}
							alt="產婦人科專門医 月花瑶子先生の写真"
						/>
					</Box>

					<Typography mt={3} variant="subtitle1" textAlign="center">
						產婦人科專門医
						<br />
						月花瑶子先生
					</Typography>

					<Typography mt={3}>
						北里大学医学部卒業。総合周産期母子医療センターでの勤務を経て、現在は都内の不妊専門クリニックに勤務。監修書籍「やさしく正しい妊活大事典（プレジデント社）」。
					</Typography>

					<Box mt={8} textAlign="center">
						<Image
							src="/top/dr-rena-takahashi.jpg"
							width={132}
							height={132}
							alt="產婦人科專門医 高橋怜奈先生の写真"
						/>
					</Box>

					<Typography mt={3} variant="subtitle1" textAlign="center">
						產婦人科專門医
						<br />
						高橋怜奈先生
					</Typography>

					<Typography mt={3}>
						東邦大学医学部卒業後、国立国際医療研究センター国府台病院、東邦大学医療センター大森病院での勤務を経て、現在は東邦大学医療センター大橋病院に勤務。医学博士。
					</Typography>
				</Box>

				<Box px={3} mt={16}>
					<Typography
						mt={8}
						variant="h6"
						component="h2"
						textAlign="center"
						color={ThemeColors[0].colors.primary[600]}
					>
						よろこびの声
					</Typography>

					<Box mt={8}>
						<ReviewComment
							title="ペアリング機能がとても良い！"
							description="生理前の気分の落ち込みがひどいのですが、なかなか自分からは言いづらいときもあるのでペアリング機能が役立っています。ケアミーから彼のLINEに生理やPMSの基礎知識を送ってくれるのも、とても助かります。"
						/>
					</Box>

					<Box mt={8}>
						<ReviewComment
							title="心の準備ができます"
							description="カレンダーでPMSの症状が出やすい期間をチェックできるので、とても助かります。イライラしたり気持ちが沈んだりしたときはカレンダーを見て「今はそういう時期だからしょうがないんだな」と納得できるし、「来週あたりはPMSがくるかも」と心の準備をすることもできるようになりました。"
						/>
					</Box>

					<Box mt={8}>
						<ReviewComment
							title="予定を立てやすくなりました"
							description="パートナーに自分の生理予定日やPMSの期間を共有できるので、二人で予定を立てやすくなりました。彼が不調になりやすい時期を気にかけてくれるようになったのは、とても嬉しい変化でした。"
						/>
					</Box>

					<Box mt={8}>
						<ReviewComment
							title="自分のPMSの傾向を知れました"
							description="ケアミーを使いはじめてから、いつ、どんな不調が起こりやすいのか把握できるようになりました。自分でもちゃんと理解していなかったPMSの傾向を知ることができました。"
						/>
					</Box>

					<Box mt={8}>
						<ReviewComment
							title="セルフケアのアドバイスがもらえた"
							description="PMSで悩んでいたとき、相談チャットの機能を使いました。PMSのセルフケアや、婦人科への相談方法など丁寧に教えてもらえて助かりました。"
						/>
					</Box>
				</Box>

				<Box mt={11}>
					<DownloadCareme />
				</Box>
			</Container>
		</>
	);
};
