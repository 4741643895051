import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Link from "next/link";

export function NewsUpdatePricing() {
	return (
		<Container component="main" maxWidth="xs" style={{ padding: 0 }}>
			<Box px={3} pt={3} pb={10}>
				<Typography component="h1" variant="h5" mb={4}>
					ケアミープレミアム料金変更についてのお知らせ
				</Typography>

				<Typography variant="body2" mt={2}>
					いつもケアミーをご利用いただきありがとうございます。 <br />
					ケアミープレミアム料金変更についてのお知らせです。
				</Typography>

				<Typography variant="body2" mt={2}>
					ケアミーはお客様に末長くご愛用いただけるよう、なるべくお手頃な料金で、より良いサービスを提供したいと考えています。しかしながら、手数料の増加などの理由からサービスの運営コストが上昇しております。
				</Typography>

				<Typography variant="body2" mt={2}>
					お客様への負担を増やすことになり大変心苦しいのですが、これらの状況を考慮して、料金変更を予定しております。詳細に関しては個別にメールでご案内しておりますのでご確認ください。
				</Typography>

				<Typography variant="body2" mt={2}>
					このお知らせに関してご質問がございましたら{" "}
					<Link href="mailto:info@healthandrights.jp">
						info@healthandrights.jp
					</Link>
					にご連絡ください。今後ともケアミーをよろしくお願いします。
				</Typography>
			</Box>
		</Container>
	);
}
